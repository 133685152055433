body {
	margin: 0;
	font-family: "Pretendard", -apple-system, BlinkMacSystemFont, "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
@font-face {
	font-family: "Pretendard";
	src: local("Pretendard-Black.woff2"),
		url("./assets/fonts/Pretendard-Black.subset.woff2") format("woff2");
	font-weight: 900;
	font-display: swap;
}

@font-face {
	font-family: "Pretendard";
	src: local("Pretendard-Bold.woff2"),
		url("./assets/fonts/Pretendard-Bold.subset.woff2") format("woff2");
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: "Pretendard";
	src: local("Pretendard-Medium.woff2"),
		url("./assets/fonts/Pretendard-Medium.subset.woff2") format("woff2");
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: "Pretendard";
	src: local("Pretendard-Regular.woff2"),
		url("./assets/fonts/Pretendard-Regular.subset.woff2") format("woff2");
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: "Pretendard";
	src: local("Pretendard-Light.woff2"),
		url("./assets/fonts/Pretendard-Light.subset.woff2") format("woff2");
	font-weight: 300;
	font-display: swap;
}

@font-face {
	font-family: "Pretendard";
	src: local("Pretendard-Thin.woff2"),
		url("./assets/fonts/Pretendard-Thin.subset.woff2") format("woff2");
	font-weight: 100;
	font-display: swap;
}
